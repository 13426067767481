<template>
    <div class="org-user">
        <div class="btn-groups">
            <div class="el_right generalQuery">
                <el-input
                    placeholder="请输入用户名"
                    v-model="searchKey"
                    class="input-with-select"
                    @keydown.native.enter="getUserList"
                >
                    <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
                </el-input>
            </div>
            <el-button
                class="add-role"
                type="primary"
                icon="el-icon-plus"
                @click="handleAdd"
            >
                批量注册
            </el-button>
        </div>
        <el-table
            border
            stripe
            :data="tableList"
            ref="multipleTable"
            :highlight-current-row="true"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            height="calc(100% - 0.35rem)"
        >
            <el-table-column
                type="selection"
                width="80"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="序号"
                type="index"
                width="80"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="用户名"
                prop="UserName"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="登录名称"
                prop="Mobile"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="手机号"
                prop="Mobile"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="账号类型"
                prop="UserType"
                align="center"
            >
                <template slot-scope="scope">
                    {{ scope.row.UserType|getUserType }}
                </template>
            </el-table-column>
            <el-table-column
                label="角色名称"
                prop="RoleNames"
                align="center"
            >
            </el-table-column>
        </el-table>
        <div class="table-page">
            <div class="el_right">
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[20,40,60,80,100]"
                    :page-size="limit"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'org-role',
    data() {
        return {
            // 表格选中数据
            checkData: [],
            loading: false,
            tableList: [],
            searchKey: '',
            page: 1,
            limit: 20,
            total: 0,
        };
    },
    filters: {
        getUserType(type) {
            const obj = {
                1: '内部用户',
                2: '外部司机',
                3: '客户',
                4: '供应商',
            };
            return obj[type] || '';
        },
    },
    created() {
        this.getUserList();
    },
    methods: {
        handleSelectionChange(val) {
            this.checkData = val;
        },
        // 获取组织列表
        async getUserList() {
            this.loading = true;
            const data = await this.$axios.get('/interfaceApi/platform/tenant_user_invite_manage/table_list?'
                + `PageIndex=${this.page}&PageSize=${this.limit}&UserName=${this.searchKey}&TenantId=${this.$takeTokenParameters('TenantId')}`);
            this.tableList = data.rows;
            this.total = data.total;
            this.loading = false;
        },

        handleAdd() {
            if (this.checkData.length > 0) {
                this.$confirm('确认要注册吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.enrollFun();
                });
            } else {
                this.$message.warning('请选择要注册的用户');
            }
        },
        enrollFun() {
            const data = {
                Mobiles: [],
                TenantId: this.$takeTokenParameters('TenantId'),
            };
            this.checkData.forEach(item => {
                data.Mobiles.push(item.Mobile);
            });
            this.$axios.post('/interfaceApi/platform/tenant_user_invite_manage/batchcreateuser', data).then(res => {
                if (res) {
                    this.$message.success('注册成功');
                    this.getUserList();
                }
            }).catch(error => {
                console.log(error);
            });
        },
        // 设置每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getUserList();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.page = val;
            this.getUserList();
        },
    },
};
</script>

<style lang="stylus" scoped>
    .org-user
        position relative
        height 100%;
        padding 0.1rem;
        background #fff;
        .btn-groups
            position: absolute;
            right: 0;
            top: -0.46rem;
            height: 0.4rem;
            display flex
            align-items center
            > *
                margin 0 0.2rem;
            .generalQuery
                height 100%;
                margin: 0.05rem 0
                >>> .el-input
                    height 100%;
                    .el-input__inner
                        width: 3rem;
                        height 100%;
                        font-size: .14rem;
                    .el-input-group__append
                        border: 1px solid #1577D2;
                        padding: 0 .1rem;
                        overflow: hidden;
                        width: .6rem;
                        font-size: .2rem;
                        text-align: center;
                        color #fff
                        .el-button
                            background: #1577D2;
                .el-input__inner
                    width 3rem
                    font-size 0.14rem
                .el-input-group__append
                    overflow hidden
                    width 0.6rem
                    font-size 0.2rem
                    text-align center
            .add-role
                padding 0
                width: 1.42rem;
                height: 0.4rem;
                background: #1577D2;
                border-radius: 0.02rem;
                font-size 0.16rem;
                color #fff;
                z-index: 2;
        .el-table
            .iconfont
                padding 0 0.1rem;
                cursor pointer
            .iconsuo:hover
                color #21BC9A
            .iconbumen:hover
                color #FF6600
            .iconxiugai:hover
                color #1577D2
            .iconlajitong:hover
                color #D60110
            >>> th
                background: #EDF0F5;
                border: 1px solid #DEDFE0;
                color #022782
            th,td
                padding 0

</style>
